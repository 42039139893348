<template>
<div>
  <div v-for="(q, qIndex) in questions.slice(0, recordingsLimit)" :key="q.id" class="border-bottom mb-2 pb-2">
    <div v-if="!config.filterUserId">
      Question from <b>{{ q.author }}</b><br/>
    </div>
    <div v-if="$debug.isOn" class="alert-info">
      UserId: {{ q.userId }}
    </div>
    <editable class="mt-2" :text="q.question" @change="(v) => updateQuestion(q, {question: v})">
      <i>"{{ q.question }}"</i>
    </editable>
    <div class="text-muted" v-if="q.date">{{ q.date.toDate() | formatDate }}</div>
    <div class="d-flex flex-row">
      <player ref="player" v-if="q.questionRecording" class="mt-2" style="flex-grow: 1" :url="q.questionRecording"/>
      <div class="ml-2 d-flex flex-column sub-mt-2 sub-mr-2" style="flex-grow: 0" v-if="config.showActionsPlay || config.showActionsSend">
        <button v-if="config.showActionsPlay" class="btn btn-primary btn-sm text-nowrap" @click="play(1, q, $refs.player[qIndex])">Play on B</button>
        <template v-if="config.showActionsSend">
          <button v-for="(key, slotIndex) in userSlots" class="btn btn-primary btn-sm text-nowrap" 
            @click="send(slotIndex, q, $refs.player[qIndex])" :key="key">Send to {{key}}</button>
        </template>
      </div>
    </div>
  </div>
  <button class="btn btn-primary" v-if="recordingsLimit < questions.length" @click="recordingsLimit += 3">Show More</button>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("questions-list", true);
import { db } from "@/services/db";
import Editable from '@/components/editable.vue';
import { hashCode } from "@/services/utils";

export default {
  components: {
    Player: () => import('@/views/tests/TestHLS.vue'),
    Editable,
  },
  props: {
    event: Object,
    config: {
      type: Object,
      default: () => { return {}; },
    }
  },
  data() {
    return {
      questions: [],
      userSlots: ['A','B','C','D'],
      recordingsLimit: 5,
    };
  },
  mounted() {
    log.log("mounted");
    let col = db.collection(`LiveEvents/${this.event.id}/questions`);
    if (this.config.filterUserId)
      col = col.where("userId", "==", this.config.filterUserId);
    this.$bind("questions", col);
    this.recordingsLimit = this.config.recordingsLimit || this.recordingsLimit;
  },
  methods: {
    updateQuestion(q, data) {
      db.collection(`LiveEvents/${this.event.id}/questions`).doc(q.id).update(data);
    },
    processQuestion(q) {
      return {
        name: q.author,
        shortBio: q.question,
        // Workaround: id needs to be unique otherwised gets cached
        id: `${q.id}-${hashCode(q.author+q.question)}`
      };
    },
    send(channel, q, player) {
      let eventUser = this.processQuestion(q);
      log.log("send", eventUser);
      this.$emit('send', channel, eventUser, player.$refs.video);
    },
    play(channel, q, player) {
      let eventUser = this.processQuestion(q);
      log.log("play", eventUser);
      this.$emit('play', channel, eventUser, player.$refs.video);
    },
  }
}
</script>

<style>

</style>