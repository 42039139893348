<template>
<div>
  <div v-if="filterUserId">
    <h2>Manage your questions</h2>
    <button class="btn btn-primary mb-2" @click="recordNew()">Record a new question</button>
  </div>
  <div v-else>
    <h2>Production</h2>
    <button class="btn btn-primary mb-2" @click="readyToProduce()">Ready to produce</button>
    <h2>Preview Questions</h2>
  </div>
  <!-- list filtered if not a moderator -->
  <questions-list
    :event="event"
    :config="{filterUserId}"
    />
</div>
</template>

<script>
import questionsList from '../components/questionsList.vue'
export default {
  components: { questionsList },
  props: {
    event: Object,
    eventUser: Object,
  },  
  computed: {
    filterUserId() {
      return this.eventUser.role == "moderator" ? null : this.eventUser.id;
    }
  },
  methods: {
    recordNew() {
      this.$parent.eventUserBehavior.on('questionRecordNew');
    },
    readyToProduce() {
      this.$parent.eventUserBehavior.on('gotoStage');
    }
  }
}
</script>

<style>

</style>